import useApi from '../../../../hooks/useApi';

import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import Modal from '../../../general/modal/Modal';
import { BulkConversion } from '@/lib/types/general';
import { useSnackbar } from 'notistack';

type DoNotBillProps = {
    tickets: any[];
    handleClose: () => null;
    handleConversions: (
        conversions: BulkConversion['successfulRequestIds']
    ) => null;
};

export const DoNotBillModal = ({
    tickets,
    handleClose,
    handleConversions
}: DoNotBillProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const [{ loading: closingTickets, error: closingError }, closeTickets] =
        useApi('', 'PUT', { manual: true });

    const handleCloseTandM = () => {
        if (!tickets || tickets.length === 0) {
            return;
        }
        closeTickets({
            url: `connectwise/serviceticket/convert/donotbill`,
            data: tickets
        }).then((result: BulkConversion) => {
            if (result.failedRequestIds.length === 0) {
                enqueueSnackbar({
                    message: 'Ticket(s) closed',
                    variant: 'success'
                });
            } else {
                enqueueSnackbar({
                    message: 'Some Ticket(s) were not closed',
                    variant: 'warning'
                });
            }
            handleConversions(result.successfulRequestIds);
            handleClose();
        });
    };

    return (
        <Modal open handleClose={handleClose} blocking>
            <Modal.Title>Close As Do Not Bill</Modal.Title>
            <Modal.Body>
                <div className="flex flex-col gap-4 pb-4">
                    <div className="text-sm text-[var(--light-text-color)] border-b-[var(--xlight-text-color)] border-b pb-4">
                        Closing {tickets?.length ?? 0} service ticket(s) as Do Not Bill
                    </div>
                </div>
                {closingError && <ErrorMessage error={closingError?.message} />}
            </Modal.Body>
            <Modal.Actions>
                <Button label="Cancel" type="secondary" onClick={handleClose} />
                <Button
                    label="Confirm"
                    onClick={handleCloseTandM}
                    disabled={closingTickets}
                    loading={closingTickets}
                />
            </Modal.Actions>
        </Modal>
    );
};
