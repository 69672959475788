import Loader from './Loader';

import styles from '../../styles/general/LoadingOverlay.module.scss';

type LoadingOverlayProps = {
    labelPosition?: 'bottom' | 'top' | 'left' | 'right';
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    spinColor?: string;
    backgroundColor?: string;
    label?: string;
    position?: 'relative' | 'absolute' | 'fixed' | 'sticky';
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
    contrast?: boolean;
    fontSize?: string;
    classes?: {
        container?: string;
    }
};

const LoadingOverlay = ({
    labelPosition = 'bottom',
    size = 'lg',
    spinColor = 'rgba(0,0,0,0.4)',
    backgroundColor = 'rgba(0,0,0,0.1)',
    label = 'Loading...',
    position = 'relative',
    top,
    left,
    right,
    bottom,
    contrast = false,
    fontSize,
    classes = {}
}: LoadingOverlayProps) => {
    const getHeight = () => {
        switch (size) {
            case 'xl':
                return '42px';
            case 'lg':
                return '26px';
            case 'md':
                return '18px';
            case 'sm':
                return '14px';
            case 'xs':
                return '12px';
            default:
                return '26px';
        }
    };

    return (
        <div
            className={[styles.overlayContainer, classes?.container].join(' ')}
            style={{
                position: position,
                top: top,
                left: left,
                right: right,
                bottom: bottom
            }}
        >
            <div
                className={[
                    styles.overlayLabel,
                    styles[`${labelPosition.toLowerCase()}Label`],
                    styles[size],
                    styles[contrast ? 'contrast' : 'normal']
                ].join(' ')}
            >
                <Loader
                    spinColor={spinColor}
                    background={backgroundColor}
                    height={getHeight()}
                />
                {label && (
                    <div
                        className={styles.label}
                        style={{ fontSize: fontSize }}
                    >
                        {label}
                    </div>
                )}
            </div>
        </div>
    );
};

export default LoadingOverlay;
