import DropdownMenu from '../../DropdownMenu';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const MenuCell = ({ options }) => {
    const handleSelect = (toggleMenu, handleClick) => {
        toggleMenu?.();
        handleClick?.();
    };

    return (
        <div>
            <DropdownMenu
                button={(toggled) => (
                    <span
                        className={[
                            'hover:text-[var(--medium-text-color)] cursor-pointer p-2',
                            toggled
                                ? 'text-[var(--medium-text-color)]'
                                : 'text-[var(--xlight-text-color)]'
                        ].join(' ')}
                    >
                        <FontAwesomeIcon icon={faEllipsis} />
                    </span>
                )}
                content={(_, handleToggle) => (
                    <div className="flex flex-col">
                        {options?.map?.((option) => (
                            <div
                                className="text-xs flex gap-2 border-b-[1px] border-b-[rgba(0,0,0,0.05)] last:border-b-0 px-3 py-2 text-[var(--light-text-color)] hover:bg-[rgba(0,0,0,0.05)] cursor-pointer"
                                onClick={handleSelect.bind(
                                    this,
                                    handleToggle,
                                    option?.handleClick
                                )}
                            >
                                <span>
                                    <FontAwesomeIcon icon={option.icon} />
                                </span>
                                <span className="select-none">
                                    {option.label}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
                orientation="left"
                width="200px"
                classes={{
                    menuContainer:
                        'shadow-lg rounded-sm border border-[rgba(0,0,0,0.05)]'
                }}
            />
        </div>
    );
};

MenuCell.propTypes = {
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.object,
            handleClick: PropTypes.func,
            tooltip: PropTypes.shape({
                tooltip: PropTypes.string,
                hoverTrigger: PropTypes.oneOf(['always', 'never', 'overflow']),
                hoverDelay: PropTypes.number
            })
        })
    )
};

export default MenuCell;
