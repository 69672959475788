import { Ref, useRef } from 'react';

import useOutsideClick from '../../../hooks/useOutsideClick';
import useToggle from '../../../hooks/useToggle';

import Loader from '../Loader';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import styles from '../../../styles/general/Button.module.scss';

type ButtonProps = {
    type?: 'primary' | 'secondary' | 'grayscale';
    variant?: 'filled' | 'border' | 'text';
    onClick: () => any;
    label: string;
    loading?: boolean;
    icon?: any;
    iconPosition?: 'left' | 'right';
    iconProps?: any;
    disabled?: boolean;
    className?: string;
    buttonRef?: Ref<HTMLButtonElement>;
    formAction?: any;
    linkTo?: string;
    newTab?: boolean;
};

const Button = ({
    type = 'primary',
    variant = 'filled',
    onClick,
    label,
    loading,
    icon,
    iconPosition = 'left',
    iconProps = {},
    disabled = false,
    className,
    buttonRef,
    formAction,
    linkTo,
    newTab = false
}: ButtonProps) =>
    linkTo ? (
        <Link
            to={disabled ? '' : linkTo}
            target={newTab ? '_blank' : ''}
            className={[
                styles.button,
                styles[type],
                styles[variant],
                disabled ? styles.disabled : null,
                className
            ].join(' ')}
        >
            {icon && iconPosition === 'left' && (
                <div className={[styles.icon, styles.left].join(' ')}>
                    <FontAwesomeIcon icon={icon} {...iconProps} />
                </div>
            )}
            {label && <div className={styles.label}>{label}</div>}
            {icon && iconPosition === 'right' && (
                <div className={[styles.icon, styles.right].join(' ')}>
                    <FontAwesomeIcon icon={icon} {...iconProps} />
                </div>
            )}
            {loading && (
                <div className={styles.loader}>
                    <Loader className={styles[`${type}-loader`]} />
                </div>
            )}
        </Link>
    ) : (
        <button
            className={[
                styles.button,
                styles[type],
                styles[variant],
                disabled ? styles.disabled : null,
                className
            ].join(' ')}
            ref={buttonRef}
            onClick={disabled ? () => null : onClick}
            type={formAction ?? 'button'}
            formAction={formAction ?? 'submit'}
            disabled={disabled || loading}
        >
            {icon && iconPosition === 'left' && (
                <div className={[styles.icon, styles.left].join(' ')}>
                    {loading ? (
                        <Loader className={styles[`${type}-loader`]} />
                    ) : (
                        <FontAwesomeIcon icon={icon} {...iconProps} />
                    )}
                </div>
            )}
            {!icon && loading && (
                <div className={[styles.icon, styles.left].join(' ')}>
                    <Loader className={styles[`${type}-loader`]} />
                </div>
            )}
            {label && <div className={styles.label}>{label}</div>}
            {icon && iconPosition === 'right' && (
                <div className={[styles.icon, styles.right].join(' ')}>
                    {loading ? (
                        <Loader className={styles[`${type}-loader`]} />
                    ) : (
                        <FontAwesomeIcon icon={icon} {...iconProps} />
                    )}
                </div>
            )}
        </button>
    );

type DropdownButtonProps = Pick<
    ButtonProps,
    | 'type'
    | 'variant'
    | 'label'
    | 'loading'
    | 'icon'
    | 'iconProps'
    | 'disabled'
    | 'className'
> & {
    options: DropdownButtonOpt[];
    handleClick: (key: string | number | DropdownButtonOpt) => any;
};

type DropdownButtonOpt = {
    key: string | number;
    label: string;
    icon?: string | any;
    iconProps?: any;
};

const DropdownButton = ({
    type = 'primary',
    variant = 'filled',
    options = [],
    handleClick = () => null,
    label,
    loading,
    icon,
    iconProps = {},
    disabled = false,
    className
}: DropdownButtonProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null);

    const [toggled, handleToggle] = useToggle();
    useOutsideClick(buttonRef, () => handleToggle(false));

    const _handleClick = (
        option: DropdownButtonOpt & {
            handleClick: DropdownButtonProps['handleClick'];
        }
    ) => {
        handleToggle(false);
        if (option?.handleClick) option.handleClick(option?.key);
        else handleClick(option?.key ? option.key : option);
    };

    return (
        <button
            className={[styles.dropdownButton, className].join(' ')}
            ref={buttonRef}
        >
            <div
                className={[
                    styles.button,
                    styles[type],
                    styles[variant],
                    disabled ? styles.disabled : null,
                    toggled ? styles.toggled : null
                ].join(' ')}
                onClick={disabled ? () => null : handleToggle}
            >
                {icon && (
                    <div
                        style={{ opacity: loading ? 0 : 1 }}
                        className={[styles.icon, styles.left].join(' ')}
                    >
                        <FontAwesomeIcon icon={icon} />
                    </div>
                )}
                {label && (
                    <div
                        style={{ opacity: loading ? 0 : 1 }}
                        className={styles.label}
                    >
                        {label}
                    </div>
                )}
                <div className={styles.dropdownIcon}>
                    <FontAwesomeIcon icon={toggled ? faCaretUp : faCaretDown} />
                </div>
                <div className={styles.loader}>
                    {loading && <Loader className={styles[`${type}-loader`]} />}
                </div>
            </div>
            {toggled && (
                <div className={styles.menu}>
                    {options.map((option) => (
                        <DropdownButtonOption
                            option={option}
                            handleClick={_handleClick}
                            key={option.key ?? option.label}
                        />
                    ))}
                </div>
            )}
        </button>
    );
};

type DropdownButtonOptionProps = {
    option: DropdownButtonOpt;
    handleClick: any;
};

const DropdownButtonOption = ({
    option,
    handleClick
}: DropdownButtonOptionProps) => (
    <div
        className="flex gap-3 py-[6px] px-3 border-b border-b-[rgba(0,0,0,0.1)] last:border-b-0 cursor-pointer hover:bg-[rgba(0,0,0,0.03)]"
        onClick={handleClick.bind(this, option)}
    >
        {option?.icon && (
            <div className="text-[var(--light-text-color)]">
                <FontAwesomeIcon
                    icon={option.icon}
                    {...(option.iconProps ?? {})}
                />
            </div>
        )}
        <p className="text-[var(--medium-text-color)]">
            {option?.label ?? option}
        </p>
    </div>
);

export default Button;
export { DropdownButton };
