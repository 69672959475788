import { useRef } from 'react';

import useApi from '../../../../hooks/useApi';

import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import Modal from '../../../general/modal/Modal';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';

const AgreementModal = ({
    companies,
    tickets,
    handleClose,
    handleConversions
}) => {
    const submitButtonRef = useRef();

    const { enqueueSnackbar } = useSnackbar();

    const {
        handleSubmit,
        control,
        setError,
        clearErrors,
        setValue,
        getValues,
        watch,
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            agreement: []
        }
    });

    const [
        { loading: convertingAgreement, error: convertingError },
        convertAgreement
    ] = useApi('', 'PUT', { manual: true });

    const handleCreate = ({ agreement }) => {
        if (
            !tickets ||
            tickets.length === 0 ||
            !agreement ||
            !agreement[0] ||
            !agreement[0].id
        ) {
            return;
        }
        convertAgreement({
            url: `connectwise/serviceticket/convert/agreement/${agreement[0]?.id}`,
            data: tickets
        }).then((result) => {
            if (result.failedRequestIds.length === 0) {
                enqueueSnackbar({
                    message: 'Ticket(s) converted to an Agreement',
                    variant: 'success'
                });
            } else {
                enqueueSnackbar({
                    message:
                        'Some Ticket(s) were not converted to an Agreement',
                    variant: 'warning'
                });
            }
            handleConversions(result.successfulRequestIds);
            handleClose();
        });
    };

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    return (
        <Modal
            open
            handleClose={handleClose}
            blocking
            classes={{
                content: 'min-h-[340px]'
            }}
        >
            <Modal.Title>Convert to Agreement</Modal.Title>
            <Modal.Body>
                <FormProvider
                    handleSubmit={handleSubmit}
                    control={control}
                    {...methods}
                >
                    <form
                        autoComplete="true"
                        onSubmit={(e) => {
                            handleSubmit(handleCreate)(e);
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        noValidate={true}
                        className="flex flex-col gap-4"
                    >
                        <div className="flex flex-col gap-2 pb-8">
                            <div className="text-sm text-[var(--light-text-color)] border-b-[var(--xlight-text-color)] border-b pb-4">
                                Converting {tickets?.length ?? 0} service
                                ticket(s)
                            </div>
                            <Controller
                                name={'agreement'}
                                control={control}
                                rules={{
                                    required: 'You must specify an agreement.'
                                }}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Agreement"
                                        error={error?.message}
                                    >
                                        <Select
                                            placeholder={'Agreement'}
                                            pagination={{
                                                url: `/connectwise/company/${companies}/agreement`
                                            }}
                                            getRowValue={(row) => row.name}
                                            selected={value}
                                            handleRowSelection={(row) =>
                                                onChange(row)
                                            }
                                            disabled={convertingAgreement}
                                        />
                                    </InputLabel>
                                )}
                            />
                            {convertingError && (
                                <ErrorMessage
                                    error={convertingError?.message}
                                />
                            )}
                            <div
                                style={{
                                    visibility: 'hidden',
                                    opacity: 0
                                }}
                            >
                                <button
                                    formAction="submit"
                                    ref={submitButtonRef}
                                />
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </Modal.Body>
            <Modal.Actions>
                <Button label="Cancel" type="secondary" onClick={handleClose} />
                <Button
                    label="Convert"
                    onClick={handleSubmitClicked}
                    disabled={convertingAgreement}
                    loading={convertingAgreement}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default AgreementModal;
