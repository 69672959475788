import React, { useEffect } from 'react';

import useApi from '../../hooks/useApi';
import useDimensions from '../../hooks/useDimensions';
import useLocalStorage from '../../hooks/useLocalStorage';
import useOutsideClick from '../../hooks/useOutsideClick';

import Logout from '../../assets/logout.png';
import { logout } from '../../js/services/auth';
import Loader from '../general/Loader';
import { TextTooltip } from '../general/Tooltip';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const NavMenu = ({ styles, menuButtonRef }) => {
    let navMenuRef = React.useRef();

    const dispatch = useDispatch();
    const expanded = useSelector((state) => state.navigation.expanded);

    const [cachedActions, updateActions] = useLocalStorage('quick-actions');
    const [{ data: quickActions, loading: quickActionsLoading }] = useApi(
        '/quick-actions',
        'GET'
    );

    useEffect(() => {
        if (!quickActions) return;
        updateActions(quickActions);
    }, [quickActions, updateActions]);

    let [width] = useDimensions({ debounce: false });

    useOutsideClick(
        [navMenuRef, menuButtonRef],
        () => {
            dispatch({ type: 'SET_EXPANDED', expanded: false });
        },
        expanded
    );

    return (
        <nav
            className={[expanded && styles.expandedMenu, styles.navMenu].join(
                ' '
            )}
            style={{ display: width <= 550 && !expanded ? 'none' : null }}
            ref={navMenuRef}
        >
            <div>
                {!cachedActions && quickActionsLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '6px'
                        }}
                    >
                        <Loader
                            spinColor="rgba(0,0,0,0.4)"
                            background="rgba(0,0,0,0.1)"
                        />
                    </div>
                ) : (
                    cachedActions
                        ?.sort?.((a, b) =>
                            a?.appName?.localeCompare?.(b?.appName)
                        )
                        ?.map?.((action) => (
                            <TextTooltip
                                tooltip={action?.appName}
                                hoverTrigger={!expanded ? 'always' : 'never'}
                                position={{
                                    x: 'right',
                                    y: 'center'
                                }}
                                offset={{
                                    x: 16,
                                    y: 0
                                }}
                                key={action?.appName}
                            >
                                <div className={styles.navItem}>
                                    <Link
                                        to={`/${action?.appName
                                            ?.replaceAll(/\s/ig, '')
                                            ?.toLowerCase?.()}`}
                                        className={styles.navLink}
                                        onClick={() =>
                                            dispatch({
                                                type: 'SET_EXPANDED',
                                                expanded: false
                                            })
                                        }
                                    >
                                        <div className={styles.iconContainer}>
                                            {action?.imageInBase64 && (
                                                <img
                                                    src={`data:image/png;base64, ${action?.imageInBase64}`}
                                                    alt={action?.appName}
                                                    className={styles.navIcon}
                                                />
                                            )}
                                        </div>
                                        <div>{action?.appName}</div>
                                    </Link>
                                </div>
                            </TextTooltip>
                        ))
                )}
            </div>
            <TextTooltip
                tooltip="Logout"
                hoverTrigger={!expanded ? 'always' : 'never'}
                position={{
                    x: 'right',
                    y: 'center'
                }}
                offset={{
                    x: 16,
                    y: 0
                }}
            >
                <div className={styles.navItem}>
                    <div className={styles.navLink} onClick={logout}>
                        <div className={styles.iconContainer}>
                            <img
                                src={Logout}
                                className={styles.navIcon}
                                alt=""
                            />
                        </div>
                        <div>Logout</div>
                    </div>
                </div>
            </TextTooltip>
        </nav>
    );
};

NavMenu.propTypes = {
    styles: PropTypes.object,
    menuButtonRef: PropTypes.object
};

export default NavMenu;
