import styles from '../../styles/general/Loader.module.scss';

type LoaderProps = {
    height?: string | number;
    background?: string;
    spinColor?: string;
    className?: string;
}

const Loader = ({ height, background, spinColor, className = ''}: LoaderProps) => (
    <div
        style={{
            height,
            width: height,
            borderColor: background,
            borderRightColor: spinColor
        }}
        className={[styles.loader, styles.simpleCircle, className].join(' ')}
    />
);

export default Loader;
