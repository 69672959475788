import { useRef } from 'react';

import useApi from '../../../../hooks/useApi';

import ErrorMessage from '../../../general/ErrorMessage';
import Button from '../../../general/input/Button';
import InputLabel from '../../../general/input/InputLabel';
import Select from '../../../general/input/Select';
import Modal from '../../../general/modal/Modal';
import { useSnackbar } from 'notistack';
import { Controller, FormProvider, useForm } from 'react-hook-form';

const ProjectModal = ({
    companies,
    tickets,
    handleClose,
    handleConversions
}) => {
    const submitButtonRef = useRef();

    const {
        handleSubmit,
        control,
        setError,
        clearErrors,
        setValue,
        getValues,
        watch,
        ...methods
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        criteriaMode: 'all',
        defaultValues: {
            project: [],
            phase: []
        }
    });

    const { enqueueSnackbar } = useSnackbar();
    const [
        {
            loading: convertingProject,
            error: convertingError
        },
        convertProject
    ] = useApi('', 'PUT', { manual: true });

    const handleCreate = ({ project, phase }) => {
        if (
            !tickets ||
            tickets.length === 0 ||
            !project ||
            !project[0] ||
            !project[0].id ||
            !phase ||
            !phase[0] ||
            !phase[0].id
        ) {
            return;
        }
        convertProject({
            url: `connectwise/serviceticket/convert/project`,
            data: {
                serviceTickets: tickets,
                projectId: project[0].id,
                phaseId: phase[0].id,
                boardId: project[0].board.id
            }
        }).then((result) => {
            if (result.failedRequestIds.length === 0) {
                enqueueSnackbar({
                    message: 'Ticket(s) converted to a Project',
                    variant: 'success'
                });
            } else {
                enqueueSnackbar({
                    message: 'Some Ticket(s) were not converted to a Project',
                    variant: 'warning'
                });
            }
            handleConversions(result.successfulRequestIds);
            handleClose();
        });
    };

    const handleSubmitClicked = () => submitButtonRef?.current?.click?.();

    const { project } = watch();

    return (
        <Modal
            open
            handleClose={handleClose}
            blocking
            classes={{
                content: 'min-h-[400px]'
            }}
        >
            <Modal.Title>Convert to Project</Modal.Title>
            <Modal.Body>
                <div className="flex flex-col gap-4 pb-4">
                    <div className="text-sm text-[var(--light-text-color)] border-b-[var(--xlight-text-color)] border-b pb-4">
                        Converting {tickets?.length ?? 0} service ticket(s)
                    </div>
                    <FormProvider
                        handleSubmit={handleSubmit}
                        control={control}
                        {...methods}
                    >
                        <form
                            autoComplete="true"
                            onSubmit={(e) => {
                                handleSubmit(handleCreate)(e);
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            noValidate={true}
                            className="flex flex-col gap-4"
                        >
                            <Controller
                                name={'project'}
                                control={control}
                                rules={{
                                    required: 'You must specify a project.'
                                }}
                                render={({
                                    field: { value, onChange },
                                    fieldState: { error }
                                }) => (
                                    <InputLabel
                                        label="Project"
                                        error={error?.message}
                                    >
                                        <Select
                                            placeholder={'Project'}
                                            pagination={{
                                                url: `/connectwise/company/${companies}/project`
                                            }}
                                            getRowValue={(row) => row.name}
                                            selected={value}
                                            handleRowSelection={(row) => {
                                                setValue('phase', []);
                                                onChange(row);
                                            }}
                                            disabled={convertingProject}
                                        />
                                    </InputLabel>
                                )}
                            />
                            {project && project[0] && (
                                <Controller
                                    name={'phase'}
                                    control={control}
                                    rules={{
                                        required: 'You must specify a phase.'
                                    }}
                                    render={({
                                        field: { value, onChange },
                                        fieldState: { error }
                                    }) => (
                                        <InputLabel
                                            label="Phase"
                                            error={error?.message}
                                        >
                                            <Select
                                                placeholder={'Phase'}
                                                pagination={{
                                                    url: `/connectwise/project/${project[0].id}/phase`,
                                                    record: project[0].id
                                                }}
                                                getRowValue={(row) =>
                                                    row.description
                                                }
                                                selected={value}
                                                handleRowSelection={(row) =>
                                                    onChange(row)
                                                }
                                                disabled={convertingProject}
                                            />
                                        </InputLabel>
                                    )}
                                />
                            )}
                            {convertingError && (
                                <ErrorMessage
                                    error={convertingError?.message}
                                />
                            )}
                            <div
                                style={{
                                    visibility: 'hidden',
                                    opacity: 0
                                }}
                            >
                                <button
                                    formAction="submit"
                                    ref={submitButtonRef}
                                />
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </Modal.Body>
            <Modal.Actions>
                <Button label="Cancel" type="secondary" onClick={handleClose} />
                <Button
                    label="Convert"
                    onClick={handleSubmitClicked}
                    disabled={convertingProject}
                    loading={convertingProject}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default ProjectModal;
