import {
    useLayoutEffect,
    useRef,
    useState
} from 'react';

import useDimensions from '../../hooks/useDimensions';
import useOutsideClick from '../../hooks/useOutsideClick';
import useOverflowDetector from '../../hooks/useOverflowDetector';
import useScrollPosition from '../../hooks/useScrollPosition';
import useToggle from '../../hooks/useToggle';

import { isFunction } from 'lodash';
import { createPortal } from 'react-dom';

import styles from '../../styles/general/Tooltip.module.scss';

type TooltipProps = {
    children: any;
    classes?: {
        tooltipContainer?: string;
        textTooltipContent?: string;
    };
    tooltip?: string | Element | undefined;
    clickTrigger?: 'overflow' | 'always' | 'never';
    hoverTrigger?: 'overflow' | 'always' | 'never';
    hoverDelay?: number;
    position?: {
        x: 'center' | 'left' | 'right';
        y: 'top' | 'center' | 'bottom';
    };
    offset?: {
        x: number;
        y: number;
    };
    width?: number;
    style?: any;
    scrollRef?: any;
};

const Tooltip = ({
    children,
    classes,
    tooltip,
    clickTrigger = 'overflow',
    hoverTrigger = 'overflow',
    hoverDelay,
    position = {
        x: 'center',
        y: 'top'
    },
    offset = {
        x: 0,
        y: 8
    },
    width: tooltipWidth = 300,
    style = {},
    scrollRef
}: TooltipProps) => {
    const containerRef = useRef(null);
    const overflowRef = useRef(null);

    const hoverTimer = useRef<NodeJS.Timeout>(null);

    const [clicked, toggleClicked] = useToggle(false);
    const [hovered, toggleHovered] = useToggle(false);

    const [windowWidth] = useDimensions();
    const [, getOverflowState] = useOverflowDetector(overflowRef);
    useScrollPosition({
        ref: scrollRef
    });

    const handleClick = (e: any) => {
        if (
            !clickTrigger ||
            clickTrigger === 'never' ||
            (clickTrigger === 'overflow' &&
                (getOverflowState as any)() === false)
        )
            return;

        toggleClicked((clicked: boolean) => !clicked);
        e.preventDefault();
        e.stopPropagation();
    };

    const handleHoverAction = (state: boolean) => {
        if (!hoverTrigger || hoverTrigger === 'never') return;

        if (state && hoverTrigger === 'overflow')
            state = (getOverflowState as any)();

        toggleHovered(state);
    };

    const handleHover = (state: boolean) => {
        if (hoverDelay) {
            if (state) {
                hoverTimer.current = setTimeout(
                    () => handleHoverAction(state),
                    hoverDelay
                );
                return;
            } else {
                if (hoverTimer.current) clearTimeout(hoverTimer.current);
                hoverTimer.current = null;
            }
        }
        handleHoverAction(state);
    };

    useOutsideClick(
        containerRef,
        toggleClicked.bind(this, false),
        clicked && clickTrigger && clickTrigger !== 'never'
    );

    return (
        <div
            className={[styles.container, classes?.tooltipContainer].join(' ')}
            onMouseEnter={handleHover.bind(this, true)}
            onMouseLeave={handleHover.bind(this, false)}
            onClick={handleClick}
            ref={containerRef}
            style={style?.container}
        >
            {(hovered || clicked) &&
                createPortal(
                    <TooltipContent
                        style={style}
                        containerRef={containerRef}
                        windowWidth={windowWidth}
                        position={position}
                        offset={offset}
                        tooltip={tooltip}
                        tooltipWidth={tooltipWidth}
                    />,
                    document.getElementById('tooltip-root') as Element
                )}
            {isFunction(children) ? children(overflowRef) : children}
        </div>
    );
};

const TextTooltip = ({
    tooltip,
    children,
    width,
    style,
    classes,
    ...rest
}: TooltipProps) => (
    <Tooltip
        tooltip={
            (
                <div className={styles.textTooltip} style={style?.textTooltip}>
                    {tooltip as any}
                </div>
            ) as any
        }
        width={width}
        style={style}
        {...rest}
        classes={classes}
    >
        {(overflowRef: any) => (
            <div
                className={[
                    styles.textTooltipContent,
                    classes?.textTooltipContent
                ].join(' ')}
                ref={overflowRef}
                style={style?.textTooltipContent}
            >
                {children}
            </div>
        )}
    </Tooltip>
);

type TooltipContentProps = Pick<TooltipProps, 'tooltip'> & {
    style?: {
        tooltip?: object;
    };
    containerRef: any;
    tooltipWidth: number;
    windowWidth: number;
    position: {
        x: 'center' | 'left' | 'right';
        y: 'top' | 'center' | 'bottom';
    };
    offset: {
        x: number;
        y: number;
    };
};

const TooltipContent = ({
    style,
    containerRef,
    position,
    offset,
    tooltipWidth,
    tooltip,
    windowWidth
}: TooltipContentProps) => {
    const tooltipRef = useRef<any>(null);

    const [transform, setTransform] = useState<string>();

    useLayoutEffect(() => {
        const { left, top, width, height } =
            containerRef?.current?.getBoundingClientRect();

        const { width: tooltipWidth } =
            tooltipRef?.current?.getBoundingClientRect();

        let y;
        switch (position.y) {
            case 'top':
                y = `${top - offset.y}px`;
                break;
            case 'center':
                y = `${top + height / 2}px`;
                break;
            case 'bottom':
                y = `${top + height + offset.y}px`;
                break;
            default:
                y = `${top - height - offset.y}px`;
                break;
        }

        let x;
        switch (position.x) {
            case 'center':
                left + tooltipWidth / 2 + width / 2 + offset.x >=
                windowWidth - 10
                    ? (x = -tooltipWidth + windowWidth - 10)
                    : (x = left + width / 2 - tooltipWidth / 2 + offset.x);
                break;
            case 'right':
                left + width + offset.x + tooltipWidth >= windowWidth - 10
                    ? (x = -tooltipWidth + windowWidth - 10)
                    : (x = left + width + offset.x);
                break;
            case 'left':
                left - tooltipWidth - offset.x <= 10
                    ? (x = 10)
                    : (x = left - tooltipWidth - offset.x);
                break;
            default:
                left + tooltipWidth / 2 + width / 2 + offset.x >=
                windowWidth - 10
                    ? (x = -tooltipWidth + windowWidth - 10)
                    : (x = left + width / 2 - tooltipWidth / 2 + offset.x);
                break;
        }

        let translations = [
            `translate3d(${x}px,${y},0)`,
            position.y === 'center'
                ? 'translateY(-50%)'
                : position.y === 'top'
                ? 'translateY(-100%)'
                : null
        ];

        setTransform(translations.join(' '));
    }, [containerRef, offset.x, offset.y, position.x, position.y, windowWidth]);

    return (
        <div
            className={styles.tooltip}
            ref={tooltipRef}
            style={{
                transform: transform,
                maxWidth: tooltipWidth,
                ...style?.tooltip
            }}
        >
            {tooltip as any}
        </div>
    );
};

export { TextTooltip };
export default Tooltip;
