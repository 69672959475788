import { useState } from 'react';

import TerritoryAssignmentGrid from './grid/TerritoryAssignmentGrid';
import WorkOrderAdminGrid from './grid/WorkOrderAdminGrid';
import Tabs from '@/components/navigation/Tabs';

const AdminViewWorkOrder = () => {
    const [tab, setTab] = useState('workorders');

    const handleTabChange = (tab) => setTab(tab);

    return (
        <div className="w-full h-full relative flex flex-col">
            <Tabs
                tabs={[
                    {
                        label: 'Work Orders',
                        key: 'workorders'
                    },
                    {
                        label: 'Territory Assignments',
                        key: 'territoryassignments'
                    }
                ]}
                active={tab}
                onChange={handleTabChange}
            />
            {tab === 'workorders' && (
                <div className="flex-1">
                    <WorkOrderAdminGrid />
                </div>
            )}
            {tab === 'territoryassignments' && (
                <div className="flex-1">
                    <TerritoryAssignmentGrid />
                </div>
            )}
        </div>
    );
};

export default AdminViewWorkOrder;
