import { Outlet } from 'react-router-dom';

const WorkOrderApproval = () => {
    return (
        <div className="w-full h-full">
            <Outlet />
        </div>
    );
};

export default WorkOrderApproval;
